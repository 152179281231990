.coin-container {
  display: flex;
  justify-content: center;
  max-width: 55%;
  margin: 12px auto;
  box-shadow: 2px 11px 24px -9px;
  border-radius: 15px 0;
  background-color: rgba(168, 223, 231, 0.626);
}
.coin-row {
  display: flex;
  flex-direction: row;
  justify-items: start;
  align-items: center;
  height: 80px;
  padding-bottom: 15px;
  width: 900px;
}
.coin {
  padding: 24px;
}
.coin img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
.coin h1 {
  font-size: 16px;
  width: 150px;
}

.coin-data {
  display: flex;
  text-align: center;
  justify-content: space-between;
  width: 100%;
}
.coin-price {
  width: 110px;
}
.coin-volume {
  width: 230px;
}
.coin-marketcap {
  width: 155px;
}
.coin-percent {
  max-width: 100%;
}
.red {
  color: #f00606;
  font-weight: bold;
}
.green {
  color: #11d811;
  font-weight: bold;
}

.coin-input,
.coin-search {
  text-align: center;
  margin: 15px;
}

.coin-input {
  padding: 10px;
  font-weight: bolder;
  font-size: 14px;
  border-radius: 20px;
  box-shadow: 2px 11px 24px -9px;
  width: 25%;
  cursor: pointer;
}
.coin-marketRank {
  padding: 0 50px;
}

.title-heading {
  display: flex;
  max-width: 50%;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  font-size: 20px;
  font-weight: bold;
}

.title-price {
  margin-left: 6em;
}

.title-name {
  margin-left: 10px;
}

.coin-img-name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}

.coin-symbol {
  margin-left: 41px;
  color: rgba(0, 0, 139, 0.941);
  font-weight: 600;
}
